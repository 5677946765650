import { BIGCOMMERCE_BUY_NOW_LINK, PDP_TAB_TYPES } from './../../../../helpers/constants/pdpBrowConstants';
import { appLocales } from '../../../../helpers/constants/appLocales';
import { getVariantLowestPrice } from '../../../../helpers/productHelpers';
import { parseLinktoString } from '../../../../helpers/utils/parse';
import { TBrow, TSecondLevelBrowNavigationItem, TTopLevelBrowNavigationItem } from '../../../../types/TBrow';
import { TNikonProduct, TNikonVariant } from '../../../../types/TNikonProduct';
import { popCommonTabFields, TProductOverviewPageContent } from '../../../../types/TProductOverviewPage';

export const extractProductInfo = (product: TNikonProduct, locale: any) => {
  const variantsWithPrice: TNikonVariant[] = filterOutVariantsWithNoPrice(product.variants);

  // Product Pricing displays on the USA and US Parts sites only.
  if (
    (locale !== appLocales.EN_US && locale !== appLocales.EN_CA && locale !== appLocales.FR_CA) ||
    !Boolean(variantsWithPrice.length)
  ) {
    return {
      productPrice: undefined,
      isDisplayStartingAt: false,
      // TODO: add product_reviews
    };
  }

  if (hasAllSamePrice(variantsWithPrice)) {
    return {
      productPrice: product.variants[0].price,
      isDisplayStartingAt: false,
      // TODO: add product_reviews
    };
  }

  return {
    productPrice: getVariantLowestPrice(product.variants).price,
    isDisplayStartingAt: true,
    // TODO: add product_reviews
  };
};

/*export const extractProductInfo = (product: TProductOverview, locale: any) => {
  let price = undefined;
  let isDisplayStartingAt = false; // is used to check if 'Starting At' text to be displayed or not
  const variantsWithPrice: TProductOverviewVariant[] = filterOutVariantsWithNoPrice(product.variants);
  // Product Pricing displays on the USA and US Parts sites only.
  if (locale === appLocales.EN_US && Boolean(variantsWithPrice.length)) {
    let lowestPricedProdVariantIdx = 0;
    if (variantsWithPrice.length > 1 && !hasAllSamePrice(variantsWithPrice)) {
      const minPrice = Math.min(...variantsWithPrice.map(({ price }) => price.centAmount));
      lowestPricedProdVariantIdx = variantsWithPrice.findIndex(({ price }) => price.centAmount == minPrice);
      isDisplayStartingAt = true;
    }
    price = variantsWithPrice[lowestPricedProdVariantIdx].price;
  }

  return {
    productPrice: price,
    isDisplayStartingAt,
    // TODO: add product_reviews
  };
};*/

export const hasAllSamePrice = (variants: TNikonVariant[]) =>
  variants.every(({ price }) => price?.centAmount === variants[0].price?.centAmount);

export const filterOutVariantsWithNoPrice = (variants: TNikonVariant[]) =>
  variants.filter((variant) => {
    if (variant.price) {
      return variant;
    }
  });

export const extractMenuTitleAndEventKey = (menuTab: string) => ({
  title: menuTab,
  eventKey: parseLinktoString(menuTab),
});

export const extractSubMenuItems = (secondLevelBrowNavigationItem: TSecondLevelBrowNavigationItem[]) => {
  return secondLevelBrowNavigationItem.map(({ subMenuTab, uid }) => ({
    ...extractMenuTitleAndEventKey(subMenuTab),
    uid,
  }));
};

export const extractDropdownMenu = (topLevelBrowNavigationBlock: TTopLevelBrowNavigationItem[]) =>
  topLevelBrowNavigationBlock.map(({ menuTab, secondLevelBrowNavigationBlock, uid }) => {
    return {
      isDropdown: secondLevelBrowNavigationBlock.length > 0,
      ...extractMenuTitleAndEventKey(menuTab),
      subMenu: extractSubMenuItems(secondLevelBrowNavigationBlock),
      uid,
    };
  });

export const displayBuyNowCTA = (product: TNikonProduct, isPDPOverviewPage: boolean, locale: string) => {
  const productAttributes = product.variants[0].attributes;

  return (
    locale !== appLocales.PT_BR &&
    isPDPOverviewPage &&
    !Boolean(product?.isArchived) &&
    Boolean(productAttributes?.isAvailableForSale)
  );
};

export const filterPDPBrowTabsMenu = (
  content: TProductOverviewPageContent,
  product: TNikonProduct,
  locale: any,
): TBrow => {
  const filteredPDPBrowNavItems: TTopLevelBrowNavigationItem[] = [];
  const variant = product.variants[0];
  const isArchived = Boolean(product.isArchived);
  const isReviewable = Boolean(variant.attributes.isReviewable || false);

  for (const browNavItem of content.pdpBrowTabsMenu.topLevelBrowNavigationBlock) {
    switch (browNavItem.menuTab) {
      case PDP_TAB_TYPES.OVERVIEW:
        if (Boolean(content.overviewTab.length)) {
          filteredPDPBrowNavItems.push(browNavItem);
        }
        break;

      case PDP_TAB_TYPES.TECH_SPECS:
        const techSpecsContentBlock = content.techSpecs.techSpecsContentBlock;

        if (
          techSpecsContentBlock?.length > 1 ||
          !techSpecsContentBlock?.[0]?.defaultTechSpecs ||
          ((techSpecsContentBlock?.[0]?.defaultTechSpecs?.productSpecs?.length ?? 0) > 0 &&
            techSpecsContentBlock?.[0]?.defaultTechSpecs?.displayDefaultBlock)
        ) {
          filteredPDPBrowNavItems.push(browNavItem);
        }

        break;

      case PDP_TAB_TYPES.ACCESSORIES:
        if (
          content.accessories.some(
            (accessory: { defaultAccessories: popCommonTabFields }) =>
              accessory.defaultAccessories?.displayDefaultBlock,
          ) &&
          containsValidProductTabData(variant, 'Accessories')
        ) {
          filteredPDPBrowNavItems.push(browNavItem);
        }
        break;

      case PDP_TAB_TYPES.RATING_N_REVIEWS:
        if (
          locale === appLocales.EN_US &&
          content.ratingsReviews.defaultRatingsReviews?.displayDefaultBlock &&
          isReviewable &&
          !isArchived
        ) {
          filteredPDPBrowNavItems.push(browNavItem);
        }
        break;

      case PDP_TAB_TYPES.COMPATIBLE_WITH:
        if (
          Boolean(content.compatibleWith.length) &&
          content.compatibleWith.some(
            (tabObj: { defaultCompatibleWith: popCommonTabFields }) =>
              tabObj.defaultCompatibleWith?.displayDefaultBlock,
          ) &&
          containsValidProductTabData(variant, PDP_TAB_TYPES.COMPATIBLE_WITH)
        ) {
          filteredPDPBrowNavItems.push(browNavItem);
        }
        break;

      case PDP_TAB_TYPES.PRODUCT_FORUM:
        if (
          locale === appLocales.EN_US &&
          Boolean(content.productForum.length) &&
          !isArchived &&
          content.productForum.some(
            (tabObj: { default_product_forum: popCommonTabFields }) =>
              tabObj.default_product_forum?.displayDefaultBlock,
          )
        ) {
          filteredPDPBrowNavItems.push(browNavItem);
        }
        break;

      case PDP_TAB_TYPES.SUPPORT:
        if (content.support.some(({ displayDefaultBlock }) => displayDefaultBlock)) {
          filteredPDPBrowNavItems.push(browNavItem);
        }
        break;
    }
  }

  return {
    ...content.pdpBrowTabsMenu,
    topLevelBrowNavigationBlock: filteredPDPBrowNavItems,
  };
};

export const containsValidProductTabData = (variant: TNikonVariant, tabType: string) => {
  let isValid = false;
  switch (tabType) {
    case PDP_TAB_TYPES.ACCESSORIES:
      if (
        Boolean(variant.attributes?.relatedAccessories?.length) ||
        Boolean(variant.attributes?.suppliedProducts?.length)
      ) {
        isValid = true;
      }
      break;
    case PDP_TAB_TYPES.COMPATIBLE_WITH:
      if (Boolean(variant.attributes?.compatibleWithProducts?.length)) {
        isValid = true;
      }
      break;
  }
  return isValid;
};

export const composeBuyButtonLink = ({
  currentPath,
  locale,
  lang,
  sku,
  productSlug,
}: {
  currentPath: string;
  locale: string;
  lang?: string;
  sku?: string;
  productSlug?: string;
}) => {
  if ([appLocales.EN_CA, appLocales.FR_CA].includes(locale)) {
    return `${BIGCOMMERCE_BUY_NOW_LINK}/p/${productSlug}/${sku}?lang=${lang}`;
  }

  return currentPath.split('/overview')[0];
};
