export class PDP_TAB_TYPES {
  static readonly OVERVIEW = 'Overview';
  static readonly TECH_SPECS = 'Tech Specs';
  static readonly ACCESSORIES = 'Accessories';
  static readonly RATING_N_REVIEWS = 'Ratings & Reviews';
  static readonly MORE = 'More';
  static readonly COMPATIBLE_WITH = 'Compatible With';
  static readonly PRODUCT_FORUM = 'Product Forum';
  static readonly SUPPORT = 'Support';
}

export const PDP_TAB_TYPES_FOR_LOCAL = {
  Overview: 'overview',
  'Tech Specs': 'techSpecs',
  Accessories: 'accessories',
  'Ratings & Reviews': 'ratings',
  More: 'more',
  'Compatible With': 'compatibleWith',
  'Product Forum': 'forum',
  Support: 'supportTab',
};

export class PDP_TAB_EVENT_KEYS {
  static readonly MORE = 'more';
}

export const BIGCOMMERCE_BUY_NOW_LINK = process.env.NEXT_PUBLIC_CANADA_BASE_URL || '';
