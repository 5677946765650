import { ENiBoostTypes } from './types/ENiBoostTypes';
import { ENikonProductTypeKeys, TNikonProduct, TNikonVariant } from '../../../../../../../types/TNikonProduct';

export const getNiBoostTypeFromProduct = (product: TNikonProduct, variant: TNikonVariant) => {
  if (product.isArchived) {
    return ENiBoostTypes.ARCHIVED;
  }

  if (Boolean(variant.attributes?.isRefurbished)) {
    return ENiBoostTypes.REFURBISHED;
  }

  const productTypeKey = product.productTypeKey;

  if (!productTypeKey) {
    return ENiBoostTypes.OTHER;
  }

  if (productTypeKey === ENikonProductTypeKeys.FILM_CAMERAS) {
    return ENiBoostTypes.FILMCAM;
  }

  if (productTypeKey === ENikonProductTypeKeys.ACCESSORIES) {
    return ENiBoostTypes.ACCESSORIES;
  }

  if (productTypeKey === ENikonProductTypeKeys.PARTS) {
    return ENiBoostTypes.PARTS;
  }

  return ENiBoostTypes.PRODUCTS;
};

export const getNiBoostTypeScore = (niBoostType: ENiBoostTypes) => {
  switch (niBoostType) {
    case ENiBoostTypes.PRODUCTS:
      return '1.6';

    case ENiBoostTypes.FILMCAM:
    case ENiBoostTypes.ACCESSORIES:
      return '1.4';

    case ENiBoostTypes.PARTS:
    case ENiBoostTypes.REFURBISHED:
      return '1.2';

    case ENiBoostTypes.ARCHIVED:
      return '1.1';

    case ENiBoostTypes.OTHER:
      return '1';
  }
};

// Regular expressions to detect Product name schemas in metadata
const productNameSubstringPatterns = /[a-zA-Z]\d|\d[a-zA-Z]|\w[-,\\(\\)/]\w/;
const dslrCamerasRegex = /^D(\w+)$/;
const coolpixCamerasRegex = /^COOLPIX [A-Z](\w+)$/;
const monarchOpticsRegex = /^MONARCH (\w+)$/;

export const getNiProductNameSubstrings = (productName: string) => {
  if (!productName) {
    return '';
  }
  const decompositionSet = new Set();
  const productNameTokens = productName.split(' ').filter((name) => name !== '');

  for (const nameToken of productNameTokens) {
    const matcher = nameToken.match(productNameSubstringPatterns);

    if (matcher) {
      const splitNameResult = productName.split(nameToken).filter((name) => name !== '');

      if (splitNameResult.length) {
        decompositionSet.add([...splitNameResult]);
      }
    } else {
      decompositionSet.add(nameToken);
    }
  }

  const dslrCameraMatch = productName.match(dslrCamerasRegex);

  if (dslrCameraMatch) {
    if (dslrCameraMatch[1]) {
      decompositionSet.add(dslrCameraMatch[1] + ' ');
    }
  }

  const coolpixCameraMatch = productName.match(coolpixCamerasRegex);

  if (coolpixCameraMatch) {
    decompositionSet.add(coolpixCameraMatch[1] + ' ');
  }

  const monarchOpticMatch = productName.match(monarchOpticsRegex);

  if (monarchOpticMatch) {
    decompositionSet.add('MONARCH' + monarchOpticMatch[1] + ' ');
  }

  return [...decompositionSet].join(' ').trim();
};
