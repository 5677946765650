import { FormatMessageParams } from 'helpers/hooks/useFormat';

export type Locales = 'en_US' | 'en_CA' | 'fr_CA' | 'es_MX' | 'es_419' | 'pt_BR';

type SpecIcon = Record<'icon', string>;
type SpecKeyword = Record<Locales, string[]>;

const specsIconsKeywords: Record<string, SpecIcon & SpecKeyword> = {
  afs: {
    icon: '/images/tech-specs/af-s.png',
    en_US: ['AF-S'],
    en_CA: ['AF-S'],
    fr_CA: ['AF-S'],
    es_MX: ['AF-S'],
    es_419: ['AF-S'],
    pt_BR: ['AF-S'],
  },
  aperture: {
    icon: '/images/tech-specs/aperture.png',
    en_US: ['Aperture'],
    en_CA: ['Aperture'],
    fr_CA: ['Ouverture'],
    es_MX: ['Apertura'],
    es_419: ['Apertura'],
    pt_BR: ['Abertura'],
  },
  bounce: {
    icon: '/images/tech-specs/bounce-function.png',
    en_US: ['Bounce Function'],
    en_CA: ['Bounce Function'],
    fr_CA: ['Fonction de rebond'],
    es_MX: ['Función de rebote'],
    es_419: ['Función de rebote'],
    pt_BR: ['Função de rebote'],
  },
  continuousShooting: {
    icon: '/images/tech-specs/continuous-shooting.png',
    en_US: ['Continuous Shooting', 'Shutter Speed', 'Frame Advance Rate'],
    en_CA: ['Continuous Shooting', 'Shutter Speed', 'Frame Advance Rate'],
    fr_CA: ['Prise de vue en continu', 'Shutter Speed', "Vitesse d'obturation", "Taux d'avancement des images"],
    es_MX: ['Disparo continuo', 'Velocidad de obturación', 'Tasa de Avance de Cuadro'],
    es_419: ['Disparo continuo', 'Velocidad de obturación', 'Tasa de Avance de Cuadro'],
    pt_BR: ['Disparo contínuo', 'Velocidade do obturador', 'Velocidade de Obturador', 'Taxa de Avanço de Quadro'],
  },
  dimensions: {
    icon: '/images/tech-specs/dimensions.png',
    en_US: ['Approx. Dimensions'],
    en_CA: ['Approx. Dimensions'],
    fr_CA: ['Approx. Dimensions', 'Dimensions approx.'],
    es_MX: ['Dimensiones aproximadas', 'Dimensiones Aprox.'],
    es_419: ['Dimensiones aproximadas', 'Dimensiones Aprox.'],
    pt_BR: ['Dimensões aproximadas', 'Dimensões Aprox.'],
  },
  focalLengthRange: {
    icon: '/images/tech-specs/focal-length.png',
    en_US: ['Focal Length Range'],
    en_CA: ['Focal Length Range'],
    fr_CA: ['Plage de Longueurs Focales'],
    es_MX: ['Rango de Longitud Focal'],
    es_419: ['Rango de Longitud Focal'],
    pt_BR: ['Faixa de Distância Focal'],
  },
  guideNumber: {
    icon: '/images/tech-specs/guide-number.png',
    en_US: ['Guide Number'],
    en_CA: ['Guide Number'],
    fr_CA: ['Nombre guide', 'Nombre guide'],
    es_MX: ['Número Guía'],
    es_419: ['Número Guía'],
    pt_BR: ['Número Guia'],
  },
  iso: {
    icon: '/images/tech-specs/iso.png',
    en_US: ['ISO Sensitivity'],
    en_CA: ['ISO Sensitivity'],
    fr_CA: ['Sensibilité ISO', 'ISO Sensitivity'],
    es_MX: ['Sensibilidad ISO'],
    es_419: ['Sensibilidad ISO'],
    pt_BR: ['Sensibilidade ISO'],
  },
  lensCoverage: {
    icon: '/images/tech-specs/lens-coverage.png',
    en_US: ['Lens Coverage'],
    en_CA: ['Lens Coverage'],
    fr_CA: ["Couverture de l'objectif"],
    es_MX: ['Cobertura de lente'],
    es_419: ['Cobertura de lente'],
    pt_BR: ['Cobertura da lente'],
  },
  megapixels: {
    icon: '/images/tech-specs/megapixels.png',
    en_US: ['Effective Pixels'],
    en_CA: ['Effective Pixels'],
    fr_CA: ['Pixels effectifs', 'Effective Pixels'],
    es_MX: ['Píxeles efectivos', 'Pixeles efectivos'],
    es_419: ['Píxeles efectivos', 'Pixeles efectivos'],
    pt_BR: ['Pixels efetivos', 'Pixels eficazes'],
  },
  monitor: {
    icon: '/images/tech-specs/monitor.png',
    en_US: ['Monitor Size'],
    en_CA: ['Monitor Size'],
    fr_CA: ["Taille de l'écran", 'Monitor Size'],
    es_MX: ['Tamaño de Monitor', 'Tamaño del Monitor'],
    es_419: ['Tamaño de Monitor'],
    pt_BR: ['Tamanho do Monitor'],
  },
  movie: {
    icon: '/images/tech-specs/movie.png',
    en_US: ['Movie'],
    en_CA: ['Movie'],
    fr_CA: ['Film', 'Movie', 'Vidéo'],
    es_MX: ['Película'],
    es_419: ['Película'],
    pt_BR: ['Filme'],
  },
  sensorSize: {
    icon: '/images/tech-specs/sensor-size.png',
    en_US: ['Sensor Size'],
    en_CA: ['Sensor Size'],
    fr_CA: ['Taille du capteur', 'Sensor Size'],
    es_MX: ['Tamaño del Sensor'],
    es_419: ['Tamaño del Sensor'],
    pt_BR: ['Tamanho do Sensor'],
  },
  storageMedia: {
    icon: '/images/tech-specs/storage.png',
    en_US: ['Storage Media'],
    en_CA: ['Storage Media'],
    fr_CA: ["Support d'enregistrement", 'Storage Media', 'Support de stockage'],
    es_MX: ['Medio de Almacenamiento', 'Medios de Almacenamiento'],
    es_419: ['Medio de Almacenamiento', 'Medios de Almacenamiento'],
    pt_BR: ['Mídia de armazenamento'],
  },
  vr: {
    icon: '/images/tech-specs/vr.png',
    en_US: ['VR'],
    en_CA: ['VR'],
    fr_CA: ['VR'],
    es_MX: ['VR'],
    es_419: ['VR'],
    pt_BR: ['VR'],
  },
  weight: {
    icon: '/images/tech-specs/weight.png',
    en_US: ['Approx. Weight'],
    en_CA: ['Approx. Weight'],
    fr_CA: ['Approx. Weight', 'Poids approx.'],
    es_MX: ['Peso Aprox.', 'Peso aproximado'],
    es_419: ['Peso Aprox.', 'Peso aproximado'],
    pt_BR: ['Peso Aprox.', 'Peso aproximado'],
  },
  battery: {
    icon: '/images/tech-specs/battery.png',
    en_US: ['Battery'],
    en_CA: ['Battery'],
    fr_CA: ['Battery', 'Pile', 'Batterie'],
    es_MX: ['Batería'],
    es_419: ['Batería'],
    pt_BR: ['Bateria'],
  },
};

export const getTechSpecIconPath = (specName: string, locale: Locales) => {
  const defaultLocale = 'en_US';
  const spec = specName.trim().toLowerCase();

  for (const specKey in specsIconsKeywords) {
    const keywords = specsIconsKeywords[specKey][locale] || specsIconsKeywords[specKey][defaultLocale];
    if (keywords?.some((keyword) => spec.includes(keyword.toLowerCase()))) {
      return specsIconsKeywords[specKey].icon;
    }
  }
  return '';
};

export const getBooleanSanitizedValue = (
  value: string,
  formatMessage: ({ id, defaultMessage }: Omit<FormatMessageParams, 'name'>) => string,
) => {
  const valueLowerCase = value?.toLowerCase();
  const affirmativeValues = ['yes', 'true'];
  const negativeValues = ['no', 'false'];

  switch (true) {
    case affirmativeValues.includes(valueLowerCase):
      return formatMessage({ id: 'yes', defaultMessage: 'Yes' });

    case negativeValues.includes(valueLowerCase):
      return formatMessage({ id: 'no', defaultMessage: 'No' });

    default:
      return value;
  }
};
