import { IBrow, ISecondLevelBrowNavigationItem, ITopLevelBrowNavigationItem } from '../interfaces/IBrow';
import { TBrow, TSecondLevelBrowNavigationItem, TTopLevelBrowNavigationItem } from '../types/TBrow';

const SecondLevelBrowNavigationItemDTO = (
  secondLevelBrowNavigationBlock: ISecondLevelBrowNavigationItem,
): TSecondLevelBrowNavigationItem => {
  const navigationItem = secondLevelBrowNavigationBlock.second_level_brow_navigation_item;

  return {
    subMenuTab: navigationItem.sub_menu_tab,
    uid: navigationItem._metadata.uid,
  };
};

const TopLevelBrowNavigationItemDTO = (
  topLevelBrowNavigationBlock: ITopLevelBrowNavigationItem,
): TTopLevelBrowNavigationItem => {
  const navigationBlock = topLevelBrowNavigationBlock.top_level_brow_navigation_item;

  return {
    menuTab: navigationBlock.menu_tab,
    secondLevelBrowNavigationBlock: navigationBlock.second_level_brow_navigation_block.map(
      SecondLevelBrowNavigationItemDTO,
    ),
    uid: navigationBlock._metadata.uid,
  };
};

export const BrowDTO = (brow: IBrow): TBrow => ({
  contentTypeUid: brow._content_type_uid,
  uid: brow.uid,
  buttonText: brow.button_text,
  title: brow.title,
  topLevelBrowNavigationBlock: brow.top_level_brow_navigation_block.map(TopLevelBrowNavigationItemDTO),
});
