import React from 'react';
import { useRouter } from 'next/router';
import { Money } from '@commercetools/frontend-domain-types/product/Money';
import classNames from 'classnames/bind';
import { Nav, Navbar } from 'react-bootstrap';
import styles from './ProductInfo.module.scss';
import { IProductInfoProps } from './types/IProductInfoProps';
import { appLocales } from '../../../../../helpers/constants/appLocales';
import { CurrencyHelpers } from '../../../../../helpers/currencyHelpers';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { TNikonVariant } from '../../../../../types/TNikonProduct';
import RatingStars from '../../../../nikon-ui/atoms/RatingStars/RatingStars';
import { tabsEventKey } from '../types/ETabKeys';
import { extractProductInfo } from '../utils';

const cx = classNames.bind(styles);

const ProductInfo = ({
  product,
  onSelectTab,
  isShowProductName,
  locale,
  isMobile = false,
  isPdpOverview,
}: IProductInfoProps) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'product' });
  const productInfo = extractProductInfo(product, locale);
  const variant = product.variants[0] as TNikonVariant;
  const isUsaStore = locale === appLocales.EN_US;
  const isArchived = product.isArchived;
  const {
    attributes: { isRefurbished, isReviewable },
  } = variant;
  const showRating = product.reviewRatingStatistics && isReviewable && isUsaStore && !isArchived;

  return (
    <Navbar.Brand data-testid="pdp-brow-brand" className={cx(styles.productInfo, { isMobile: isMobile })} as={'div'}>
      {locale !== appLocales.PT_BR && isShowProductName && (
        <h1 className={styles.productName} data-testid="brow-product-name">
          {product.name}
          {isRefurbished && locale === appLocales.EN_US && (
            <span data-testid="refurbished-message">
              &nbsp; (
              {formatMessage({
                id: 'product.refurbished',
                defaultMessage: 'Refurbished',
              })}
              )
            </span>
          )}
        </h1>
      )}

      {Boolean((productInfo?.productPrice as Money)?.centAmount) && !isArchived && (
        <p className={cx(styles.productPrice, { 'd-none d-md-block': !isMobile })} data-testid="brow-product-price">
          {productInfo.isDisplayStartingAt && (
            <span data-testid="price-starting-at">
              {formatMessage({ id: 'price.startingAt', defaultMessage: 'Starting at' })}&nbsp;
            </span>
          )}
          {CurrencyHelpers.formatForCurrency(productInfo.productPrice as Money, locale)}
        </p>
      )}

      {showRating && (
        <Nav.Link
          className={cx(styles.ratingSection, { 'd-none d-md-block': !isMobile })}
          onClick={() => onSelectTab(tabsEventKey.RATING_AND_REVIEW)}
          href={
            isPdpOverview
              ? `#${tabsEventKey.RATING_AND_REVIEW}`
              : `${router.asPath}/overview#${tabsEventKey.RATING_AND_REVIEW}`
          }
          data-testid="brow-rating-section"
        >
          <RatingStars product={product} variant={variant} locale={locale} />
        </Nav.Link>
      )}
    </Navbar.Brand>
  );
};

export default ProductInfo;
