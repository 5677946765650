// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import Head from 'next/head';
import { ISwiftypeMetaProps } from './types/ISwiftypeMetaProps';
import { getNiBoostTypeFromProduct, getNiBoostTypeScore, getNiProductNameSubstrings } from './utils';
import { getAppropriateSkuName } from '../../../../../../../helpers/productHelpers';

const SwiftypeMeta = ({ product, variant }: ISwiftypeMetaProps) => {
  const newUntil = variant?.attributes?.newUntil ? new Date(variant?.attributes?.newUntil) : null;
  const skuName = getAppropriateSkuName(product.name || '', variant);
  const isArchived = Boolean(product.isArchived);
  const {
    attributes: { isAvailableForSale },
  } = variant;
  const metaDescription = product.metaDescription || product.description;
  const metaTitle = product.metaTitle || product.name;
  const searchKeywords = product.searchKeywords || [];
  const niBoostType = getNiBoostTypeFromProduct(product, variant);
  const niBoostTypeScore = getNiBoostTypeScore(niBoostType);

  return (
    <Head>
      <meta
        class="swiftype"
        name="niProductRatingVL"
        data-type="float"
        content={product.reviewRatingStatistics?.averageRating.toFixed(1).toString() || '0'}
      />
      <meta
        class="swiftype"
        name="niProductRatingCt"
        data-type="integer"
        content={product.reviewRatingStatistics?.count || 0}
      />
      <meta class="swiftype" name="image" data-type="enum" content={variant.representativeImage} />
      <meta
        class="swiftype"
        name="niBoostProductIsOnSale"
        data-type="float"
        content={Boolean(isAvailableForSale) ? '1' : '0'}
      />
      <meta class="swiftype" name="description" data-type="string" content={metaDescription} />
      <meta class="swiftype" name="niProductStatus" data-type="enum" content={isArchived ? 'archived' : 'active'} />
      <meta
        class="swiftype"
        name="niBoostProductIsNew"
        data-type="float"
        content={newUntil !== null && newUntil.valueOf() - new Date().valueOf() > 0 ? '1' : '0'}
      />
      <meta
        class="swiftype"
        name="type"
        data-type="enum"
        content={product.categories[0]?.categoryKey === 'cat-accessories' ? 'Accessories' : 'Products'}
      />
      <meta class="swiftype" name="niBoostType" data-type="float" content={niBoostTypeScore} />
      <meta class="swiftype" name="sections" data-type="string" content={metaDescription} />
      <meta
        class="swiftype"
        name="niProductNameSubstrings"
        data-type="text"
        content={getNiProductNameSubstrings(product.name)}
      />
      <meta class="swiftype" name="niProductNumbers" data-type="string" content={variant.sku} />
      <meta class="swiftype" name="niIsAvailable" data-type="enum" content={Boolean(isAvailableForSale).toString()} />
      <meta class="swiftype" name="niProductName" data-type="string" content={skuName} />
      <meta class="swiftype" name="published_at" data-type="date" content={product.createdAt} />
      <meta class="swiftype" name="niSiteType" data-type="enum" content="IMG" />
      <meta class="swiftype" name="keywords" data-type="string" content={searchKeywords.toString()} />
      <meta class="swiftype" name="niDefaultTitle" data-type="string" content={metaTitle} />
      {variant.attributes.reviewableDate && (
        <meta
          className="swiftype"
          name="niReviewable_at"
          data-type="date"
          content={variant.attributes.reviewableDate}
        />
      )}
    </Head>
  );
};

export default SwiftypeMeta;
